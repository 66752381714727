<template>
  <section v-if="siteSettings" class="hero is-fullheight" style="background-color: #F1F1F1;">
    <vue-headful :title="pageTitle" />
    <div class="hero-body">
      <div class="container" style="width: 99vw;">
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <div v-if="!missingUserId" id="changePassword">
              <div>
                <figure class="has-text-centered">
                  <img :src="activeSite.logoUrl" style="max-width: 250px;" />
                </figure>
                <form v-on:keyup.enter="changePassword">
                  <div class="field">
                    <label class="label is-size-7">New Password</label>
                    <div class="control">
                      <input
                        class="input"
                        type="password"
                        name="password"
                        v-model="input.newPassword"
                      />
                    </div>
                  </div>
                  <div style="padding: 10px 0px 20px 40px;" class="is-size-7">
                    <div v-for="item in passwordValidation" :key="item.msg + item.isValid">
                      <i :class="item.isValid ? 'far fa-check-circle has-text-accent': 'fas fa-times has-text-danger'"></i>
                      : {{ item.msg }}
                    </div>
                  </div>
                  <div class="field">
                    <label class="label is-size-7">Confirm Password</label>
                    <div class="control">
                      <input
                        class="input"
                        type="password"
                        name="password"
                        v-model="input.password2"
                      />
                    </div>
                  </div>

                  <div class="is-clearfix">
                    <div class="field is-pulled-right">
                      <div class="control">
                        <a class="button is-accent" v-on:click="changePassword">Change Password</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div v-else>
              <b>Missing required URL data.</b> <br/> Please copy and paste the whole link or click the button in the email.
            </div>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

export default {
  name: 'changePassword',
  data () {
    return {
      input: {
        newPassword: '',
        password2: ''
      },
      missingUserId: false,
      siteSettings: {},
      pageName: 'changePassword'
    }
  },
  computed: {
    ...mapState([activeSite]),
    pageTitle () {
      return this.pageName + ' - ' + this.activeSite.displayName
    },
    passwordValidation () {
      return this.validatePassword(
        this.input.newPassword,
        this.input.password2,
        this.siteSettings
      )
    }
  },
  async created () {
    if (!this.$route.query.userId) {
      this.missingUserId = true
    }

    if (!this.$route.query.resetToken) {
      this.missingUserId = true
    }

    let resetToken = this.$route.query.resetToken

    while (resetToken.includes('%3D') || resetToken.includes('%2B')) {
      resetToken = resetToken.replace('%3D', '=').replace('%2B', '+')
    }

    await this.getSiteSettings()
  },
  methods: {
    async changePassword () {
      try {
        if (
          this.passwordValidation.some(x => !x.isValid) ||
          (this.input.newPassword && this.input.newPassword.trim() === '')
        ) {
          this.errorToast("Password doesn't meet requirements or is blank")
          return
        }

        let route = `/user/change-password-with-token`
        let userId = this.$route.query.userId
        if (!Number.isInteger(userId)) {
          userId = this.$route.query.userId.replace(/\D/g, '')
        }
        let payload = {
          ...this.input,
          resetToken: this.$route.query.resetToken,
          userId: Number(userId)
        }
        let response = await this.axios.post(route, payload)

        try {
          response = JSON.parse(response)
        } catch (error) {
          // do nothing
        }

        if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
          this.successToast('Success!', { name: 'Login' })
        } else if (parseInt(response.status) === 400) {
          this.errorToast(response.response.data.errors[0].description)
        }
      } catch (error) {
        this.handleApiErr(error)
      } finally {
      }
    }
  }
}
</script>
